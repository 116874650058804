/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.4
 *
*/
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Variables, Mixins
@import "variables";
@import "mixins";

// INSPINIA Theme Elements
@import "typography";
@import "navigation";
@import "top_navigation";
@import "buttons";
@import "badgets_labels";
@import "elements";
@import "sidebar";
@import "base";
@import "pages";
@import "chat";
@import "metismenu";
@import "spinners";

// Landing page styles
@import "landing";

// RTL Support
//@import "rtl";

// For demo only - config box style
//@import "theme-config";

// INSPINIA Skins
@import "skins";
@import "md-skin";

// Media query style
@import "media";

// Custom style
// Your custom style to override base style
@import "custom";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }

  #page-wrapper {
    margin: 0;
  }
}
#related-product-search{
	display:none;
}
.select2{
	width:100%!important;
}












